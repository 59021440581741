import firebase from "firebase/app";
import "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBTw3jOAvh4Hap99ilu1Jb2_sD8FkYLi80",
  authDomain: "ttk-admin.firebaseapp.com",
  databaseURL: "gs://ttk-admin.appspot.com",
  projectId: "ttk-admin",
  storageBucket: "ttk-admin.appspot.com",
  messagingSenderId: "698991175951",
  appId: "1:698991175951:web:2831baa987ac96ac52b67a",
  measurementId: "G-1QYVYYV4K5"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export default storage;